<template>
  <div class="settings">
    <Header
      :back="true"
      :text="$t('Components.Settings.settings')"
      :title="$t('Components.Settings.change_avatar')"
    >
      <h5 class="center-text header-title">
        {{ $t('Components.Settings.change_avatar') }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card
        v-if="!image"
      >
        <div class="settings_link--container">
          <div class="flex">
            <RoundResourceIcon
              :src="CAMERA_ICON_PATH"
              type="settings reverse"
            />
            <h5 class="margin margin-medium margin-left setting_link--text">
              {{ $t('Components.Settings.upload_picture') }}
            </h5>
          </div>
          <span
            class="upload-image green settings_icon--right"
            @click="$refs.file.click()"
          >
            <input
              ref="file"
              type="file"
              accept="image/*"
              @click="setIsLoading()"
              @change="uploadImage($event)"
            >
            <icon
              icon="plus-circle"
              class="icon-32 right"
            />
          </span>
        </div>
      </Card>

      <!-- Placeholder / Loading -->

      <Card
        v-if="isLoading"
      >
        <div class="upload-cropper margin margin-medium margin-bottom animation shimmer" />
        <div class="buttons">
          <div class="fake-btn animation shimmer margin-right" />
          <div class="fake-btn animation shimmer margin-left" />
        </div>
      </Card>

      <Card
        v-if="image"
      >
        <div class="upload">
          <Cropper
            ref="cropper"
            class="upload-cropper margin margin-medium margin-bottom"
            :src="image"
            :stencil-component="$options.components.SettingsCropStencil"
          />
          <div class="buttons">
            <Button
              variant="primary"
              class="margin-right"
              @click="cropImage"
            >
              {{ $t('Components.Settings.crop') }}
            </Button>
            <Button
              variant="danger"
              class="margin-left"
              @click="cancle"
            >
              {{ $t('Components.Settings.cancle') }}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
/* Service, States, Helpers */
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import UserService from '@/Services/User/user.service';
import UserState from '@/singletons/user.state.singleton';

/* Components */
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
import Button from '@/components/button/button.vue';
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import SettingsCropStencil from './components/settings.crop.stencil.vue';

const CAMERA_ICON_PATH = '/icons/camera.svg';

export default {
  components: {
    Cropper,
    Header,
    Button,
    Card,
    // eslint-disable-next-line vue/no-unused-components
    SettingsCropStencil,
    RoundResourceIcon,
  },
  data() {
    return {
      image: '',
      CAMERA_ICON_PATH,
      isLoading: false,
      MAX_WIDTH: 200, // if we resize by width, this is the max width of compressed image
      MAX_HEIGHT: 200, // if we resize by height, this is the max height of the compressed image
    };
  },
  methods: {
    cancle() {
      this.image = '';
      this.isLoading = false;
    },
    setIsLoading() {
      this.isLoading = true;
    },
    async cropImage() {
      const result = this.$refs.cropper.getResult();
      const { width, height } = result.coordinates;
      const widthRatioBlob = await this.compressImage(result.canvas, this.MAX_WIDTH / width, width, height);
      const heightRatioBlob = await this.compressImage(result.canvas, this.MAX_HEIGHT / height, width, height);
      // Take the compression that is smaller
      const canvasData = widthRatioBlob.size < heightRatioBlob.size ? widthRatioBlob : heightRatioBlob;
      const fd = new FormData();
      fd.append('avatar', canvasData);
      UserService.updateAvatar(UserState.username, fd);
      this.image = '';
      this.isLoading = false;
      this.$router.go(-1);
    },
    uploadImage(event) {
      this.isLoading = true;
      // Reference to the DOM input element
      const input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        const reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.image = e.target.result;
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
      }
      this.isLoading = false;
    },
    compressImage(image, scale, initalWidth, initalHeight) {
      return new Promise((resolve) => {
        const canvas = document.createElement('canvas');
        canvas.width = scale * initalWidth;
        canvas.height = scale * initalHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        ctx.canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/png');
      });
    },
  },
};
</script>

<style lang="scss">
@import './style/settings.scss';

.settings {
  .upload-image {
    cursor: pointer;
    &.green {
      color: var(--primary-color);
      fill: var(--primary-color);
    }

    input {
      display: none;
      span {
        color: white;
      }
    }
  }
  .buttons{
    justify-content: space-between;
    display: flex;
  }
  .animation.upload-cropper {
    min-height: 19rem;
    width: 100%;
  }
  .animation.fake-btn {
    width: 10rem;
    margin: 0;
    border-radius: 0;
    height: 2.5rem !important;
    &:hover {
      cursor: none;
    }
  }
}
</style>
